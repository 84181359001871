import FlashLib from 'flashlib_onlyplay';
import EntryPoint from 'Engine/EntryPoint';
// import Animation from 'Engine/base/animation/Animation';
// import SoundManager from 'Engine/soundManager/SoundManager';
import WinAmountAnimated from '../winAmountAnimated/winAmountAnimated';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
// import { sounds } from '../utils/sounds';
// import { getRandom } from 'Engine/utils/getRandom';
import ParticleSystem from 'Engine/particles/particleSystem';
import particlesConfig from 'Engine/particles/particleConfig';
import { goldStyle, pinkStyle } from '../../textStyles/styles';
import eEventTypes from '../../enums/eEventTypes';
import SoundManager from '../../libs/game-engine/src/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';
import eSoundVolume from '../../sounds/eSoundVolume';

export default class ControllerFreeSpinsWindow extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    // this._page = EntryPoint.GameModel.isFreeSpinsMode ? 1 : -1;
    this.timeout = null;
    this.interactive = true;
    this._totalWinDuration = 5000;
    this._addFreeSpinsDuration = 3000;
    this._delayBeforeClose = 2000;

    this.init();
    // this.initParticles();
    this.addListeners();
    this.hide();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_ADD_START, this.initPage1, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_ADD_END, this.hide, this);

    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_OUT_START, this.initPage2, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_OUT_END, this.hide, this);

    this.on('click', () => GlobalDispatcher.dispatch(eEventTypes.EET_SPIN_CLICK));
    this.on('touchend', () => GlobalDispatcher.dispatch(eEventTypes.EET_SPIN_CLICK));
  }

  init() {
    this.content = this.getChildByName('content');

    this.winAmountCounter = new WinAmountAnimated();
    this.winAmountCounter.on('complete', this._onWinAnimationEnd.bind(this));
  }

  hide() {
    // SoundManager.stop(sounds.SCATTER_WIN.name);
    this.visible = false;
    // this.coinsContainer.visible = false;
    // this.coinParticles.stop();
    this.winAmountCounter.stop();
    clearTimeout(this.timeout);
  }

  initPage1() {
    this.visible = true;

    this.content.goToFrame(1);
    this.symbol = this.content.getChildByName('symbol');
    this.titleText = this.content.getChildByName('freespins_start_label1');
    this.title2Text = this.content.getChildByName('freespins_start_label2');
    this.freeSpinsAmount = this.content.getChildByName('amount');

    this.freeSpinsAmount.style = { ...this.freeSpinsAmount.style, ...goldStyle };
    this.titleText.style = { ...this.titleText.style, ...pinkStyle };
    this.title2Text.style = { ...this.title2Text.style, ...pinkStyle };
    this.title2Text.fitSize(true, true);

    this.freeSpinsAmount.text = EntryPoint.GameModel.freeSpins.amount_given;
    SoundManager.play(eSoundType.EST_WIN_FREESPINS, eSoundVolume[eSoundType.EST_WIN_FREESPINS], false, 'winning');

    this.timeout = setTimeout(this._complete.bind(this), this._addFreeSpinsDuration);
  }

  initPage2() {
    this.visible = true;

    this.content.goToFrame(2);
    this.titleText = this.content.getChildByName('freespins_total_win_label1');
    this.title2Text = this.content.getChildByName('freespins_total_win_label2');
    this.totalWinAmount = this.content.getChildByName('amount');

    this.totalWinAmount.style = { ...this.totalWinAmount.style, ...goldStyle };
    this.titleText.style = { ...this.titleText.style, ...pinkStyle };
    this.title2Text.style = { ...this.title2Text.style, ...pinkStyle };
    this.title2Text.fitSize(true, true);

    // this.coinsContainer.visible = true;
    // this.coinParticles.start();
    SoundManager.play(eSoundType.EST_FREESPINS_TOTAL_WIN, eSoundVolume[eSoundType.EST_FREESPINS_TOTAL_WIN], false, 'winning');

    this.animateValue(EntryPoint.GameModel.freespinsTotalWin, this.totalWinAmount, this._totalWinDuration, EntryPoint.GameModel.currencyInfo.decimals, EntryPoint.GameModel.currencyInfo.symbol);
    // this.animateValue(100000, this.totalWinAmount, 'BTC');
  }

  initParticles() {
    const x = this.displayData.width / 2;
    const y = this.content.height / 2.5;

    this.coinsContainer = new PIXI.Container();
    this.coinsContainer.x = x;
    this.coinsContainer.y = y;

    this.coinParticles = new ParticleSystem(this.coinsContainer, { animationType: 'coins', animationName: 'coin' }, {
      ...particlesConfig.coinParticlesConfig,
      x0min: 0,
      x0max: 0,
      y0min: 0,
      y0max: 0,
      v0max: 1.4,
      angle0From: -Math.PI / 180 * 120,
      angle0To: -Math.PI / 180 * 60,
      count: 160
    });

    this.addChild(this.coinsContainer);
  }

  animateValue(value, element, duration, decimals, postfix) {
    this.winAmountCounter.animate(0, value, decimals, duration, element, postfix);
  }

  _onWinAnimationEnd() {
    this.timeout = setTimeout(this._complete.bind(this), this._delayBeforeClose);
  }

  _complete() {
    GlobalDispatcher.dispatch(eEventTypes.EET_FREESPINS_WINDOW_SHOWED);
  }
}

